import { ErrorHandlerProviderProps } from '@sixfold/app-utils/dist/types/error_handler.d';

import { getEmbedConfig } from './lib/data';

const embedConfig = getEmbedConfig();
const sentryOptions: ErrorHandlerProviderProps['sentryOptions'] =
  embedConfig !== null && embedConfig.sentry_dsn
    ? {
        isEnabled: true,
        isHttpClientCaptureEnabled: true,
        dsn: embedConfig.sentry_dsn,
        release: embedConfig.release,
        environment: embedConfig.sentry_environment,
        thirdPartyErrorFilter: {
          applicationKey: embedConfig.sentry_app_key ?? 'customer-web-appkey',
        },
        denyUrls: [/https?:\/\/((cdn|www)\.)?inspectlet\.com/, /https:\/\/tiles-[a-zA-Z0-9]\.alk\.com/],
        ignoreErrors: [
          /ResizeObserver loop limit exceeded/,
          /ResizeObserver loop completed with undelivered notifications/,
          // Fix by: https://app.asana.com/0/1202007030618467/1205798219208199
          /Response not successful: Received status code 401/,
          /**
           * React error thrown when something outside modifies the DOM
           * Source could be a browser extension or browser's built-in translate feature
           */
          /Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node./,
          /Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node./,
        ],
      }
    : { isEnabled: false };

export { sentryOptions };
